import React from 'react';
import '../App.css';

const BottomNav = ({ setPage }) => {
  const handleNavigation = (page) => {
    setPage(page);
  };

  return (
    <div className="bottom-nav">
      <button
        className="bottom-nav-button"
        onClick={() => handleNavigation('dashboard')}
      >
        🏠
        <span>Главная</span>
      </button>

      <button
        className="bottom-nav-button"
        onClick={() => handleNavigation('upgrades')}
      >
        🔧
        <span>Улучшения</span>
      </button>

      <button
        className="bottom-nav-button"
        onClick={() => handleNavigation('stats')}
      >
        📊
        <span>Статистика</span>
      </button>

      <button
        className="bottom-nav-button"
        onClick={() => handleNavigation('settings')}
      >
        ⚙️
        <span>Настройки</span>
      </button>
    </div>
  );
};

export default BottomNav;
