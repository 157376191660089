import React from 'react';
import '../App.css';

const TopBar = ({ incomePerHour, coins }) => {
  return (
    <div className="top-bar">
      <div className="income-hourly">
        💰 {incomePerHour} / час
      </div>
      <div className="coin-balance">
        🪙 {coins}
      </div>
    </div>
  );
};

export default TopBar;
