// src/context/GameContext.js

import React, { createContext, useState, useEffect } from 'react';

// Создаём контекст
export const GameContext = createContext();

// Провайдер контекста
export const GameProvider = ({ children }) => {
  // Глобальные состояния:
  const [page, setPage] = useState('dashboard');

  const [coins, setCoins] = useState(0);
  const [secondsCount, setSecondsCount] = useState(1);
  const [minutesCount, setMinutesCount] = useState(0);
  const [hoursCount, setHoursCount] = useState(0);

  const [secondsMultiplier, setSecondsMultiplier] = useState(1);
  const [minutesMultiplier, setMinutesMultiplier] = useState(1);
  const [hoursMultiplier, setHoursMultiplier] = useState(1);

  // Telegram user
  const [tgUser, setTgUser] = useState(null);
  const [notTelegram, setNotTelegram] = useState(false);

  // Эффект, инициализирующий Telegram WebApp
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const initDataUnsafe = tg.initDataUnsafe || {};
      if (initDataUnsafe.user) {
        setTgUser(initDataUnsafe.user);
      }
      tg.ready();
    } else {
      setNotTelegram(true);
    }
  }, []);

  // Считаем доход в час
  const calculateIncomePerHour = () => {
    return (
      (secondsCount * secondsMultiplier +
        minutesCount * minutesMultiplier * 60 +
        hoursCount * hoursMultiplier * 3600) * 60
    );
  };

  // Сохранение прогресса
  const saveProgress = async () => {
    if (!tgUser) {
      alert("Нет информации о Telegram-пользователе!");
      return;
    }
    const body = {
      user_id: tgUser.id,
      coins,
      seconds_count: secondsCount,
      minutes_count: minutesCount,
      hours_count: hoursCount,
      initData: window.Telegram?.WebApp?.initData
    };
    try {
      const res = await fetch('/api/save_progress', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      const data = await res.json();
      if (!res.ok) {
        alert("Ошибка: " + JSON.stringify(data));
      } else {
        alert("Сохранено! " + JSON.stringify(data));
      }
    } catch (err) {
      console.error("Error saving progress:", err);
    }
  };

  // Загрузка прогресса
  const loadProgress = async () => {
    if (!tgUser) {
      alert("Нет информации о Telegram-пользователе!");
      return;
    }
    try {
      const res = await fetch(`/api/get_progress/${tgUser.id}`);
      const data = await res.json();
      if (res.ok) {
        setCoins(data.coins || 0);
        setSecondsCount(data.seconds_count || 0);
        setMinutesCount(data.minutes_count || 0);
        setHoursCount(data.hours_count || 0);
        alert("Загружено!");
      } else {
        alert("Ошибка загрузки: " + JSON.stringify(data));
      }
    } catch (err) {
      console.error("Error loading progress:", err);
    }
  };

  // Провайдер отдаёт всем потомкам объекты
  return (
    <GameContext.Provider
      value={{
        page,
        setPage,
        coins,
        setCoins,
        secondsCount,
        setSecondsCount,
        minutesCount,
        setMinutesCount,
        hoursCount,
        setHoursCount,
        secondsMultiplier,
        setSecondsMultiplier,
        minutesMultiplier,
        setMinutesMultiplier,
        hoursMultiplier,
        setHoursMultiplier,
        tgUser,
        notTelegram,
        calculateIncomePerHour,
        saveProgress,
        loadProgress
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
