import React, { useContext } from 'react';
import Dashboard from './components/Dashboard';
import Upgrades from './pages/Upgrades';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import BottomNav from './navigation/BottomNav';
import TopBar from './components/TopBar';
import './App.css';

// (3)(4) Используем глобальный State через Context
import { GameContext, GameProvider } from './context/GameContext';

function AppContainer() {
  // Достаём стейты и методы из контекста
  const {
    page,
    setPage,
    coins,
    secondsCount,
    minutesCount,
    hoursCount,
    notTelegram,
    tgUser,
    saveProgress,
    loadProgress,
    calculateIncomePerHour
  } = useContext(GameContext);

  // Если открыто не в Telegram
  if (notTelegram) {
    return <h1>Эта страница работает только внутри Telegram WebApp!</h1>;
  }

  // Рендерим страницы
  const renderPage = () => {
    switch (page) {
      case 'dashboard':
        return <Dashboard />;
      case 'upgrades':
        return <Upgrades />;
      case 'stats':
        return <Stats />;
      case 'settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div>
      <TopBar incomePerHour={calculateIncomePerHour()} coins={coins} />

      <div style={{ marginTop: 70, textAlign: 'center' }}>
        <button onClick={saveProgress}>Сохранить прогресс</button>
        <button onClick={loadProgress}>Загрузить прогресс</button>
      </div>

      {renderPage()}

      <BottomNav setPage={setPage} />
    </div>
  );
}

// Оборачиваем всё в GameProvider
function App() {
  return (
    <GameProvider>
      <AppContainer />
    </GameProvider>
  );
}

export default App;
