import React from 'react';
import '../App.css';

const ProgressBar = ({ progress = 50 }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
