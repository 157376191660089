import React, { useContext } from 'react';
import ProgressBar from './ProgressBar';
import '../App.css';
import { GameContext } from '../context/GameContext';

const Dashboard = () => {
  // Забираем всё нужное из контекста
  const {
    secondsCount,
    minutesCount,
    hoursCount,
    secondsMultiplier,
    minutesMultiplier,
    hoursMultiplier
  } = useContext(GameContext);

  // Считаем текущий доход (в сек) прямо тут (или можно взять из контекста)
  const calculateIncome = () => {
    return (
      secondsCount * secondsMultiplier +
      minutesCount * minutesMultiplier * 60 +
      hoursCount * hoursMultiplier * 3600
    );
  };

  return (
    <div className="dashboard-container">
      {/* Прогресс-бар */}
      <ProgressBar />

      {/* Общий доход */}
      <div className="income-display">
        📈 Общий доход: {calculateIncome()} монет
      </div>

      {/* Счетчики купленных единиц */}
      <div className="item-container">
        <div className="item-row">
          <span>⏱️ Купленные секунды: {secondsCount}</span>
          <span className="multiplier">X{secondsMultiplier}</span>
        </div>

        <div className="item-row">
          <span>🕰️ Купленные минуты: {minutesCount}</span>
          <span className="multiplier">X{minutesMultiplier}</span>
        </div>

        <div className="item-row">
          <span>⏳ Купленные часы: {hoursCount}</span>
          <span className="multiplier">X{hoursMultiplier}</span>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
