import React from 'react';
import '../App.css';

const Settings = () => {
  return (
    <div className="settings-container">
      <h2>⚙️ Настройки</h2>

      <div className="settings-option">
        <span>🔈 Звук</span>
        <button className="toggle-button">Вкл / Выкл</button>
      </div>

      <div className="settings-option">
        <span>🔔 Уведомления</span>
        <button className="toggle-button">Вкл / Выкл</button>
      </div>
    </div>
  );
};

export default Settings;
