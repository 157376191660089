  /*********************************************
   * Upgrades.js
   *********************************************/
  import React, { useContext } from 'react';
  import '../App.css';
  import { GameContext } from '../context/GameContext';

  const Upgrades = () => {
    // Берём всё из контекста
    const {
      coins,
      setCoins,
      secondsCount,
      setSecondsCount,
      minutesCount,
      setMinutesCount,
      hoursCount,
      setHoursCount,
      tgUser
    } = useContext(GameContext);

    /**********************************************
     * Экспоненциальная формула стоимости
     **********************************************/
    const costSecond = (currentSec) => {
      if (currentSec <= 1) {
        return 10;
      }
      return 10 * Math.pow(2, currentSec - 1);
    };

    const costMinute = (currentMin) => {
      return 600 * Math.pow(2, currentMin);
    };

    const costHour = (currentHour) => {
      return 36000 * Math.pow(2, currentHour);
    };

    // Вызов на сервер /api/save_progress (опционально)
    const saveProgressToServer = async (newCoins, newSec, newMin, newHour) => {
      if (!tgUser) {
        alert("Нет информации о Telegram-пользователе!");
        return false;
      }
      const body = {
        user_id: tgUser.id,
        coins: newCoins,
        seconds_count: newSec,
        minutes_count: newMin,
        hours_count: newHour,
        initData: window.Telegram?.WebApp?.initData || ""
      };
      try {
        const res = await fetch('/api/save_progress', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        });
        const data = await res.json();
        if (!res.ok) {
          alert("Ошибка сохранения: " + JSON.stringify(data));
          return false;
        }
        console.log("Сохранено:", data);
        return true;
      } catch (err) {
        console.error("Ошибка при save_progress:", err);
        return false;
      }
    };

    /**********************************************
     * Покупка (n+1)-й секунды
     **********************************************/
    const buySecond = async () => {
      const cost = costSecond(secondsCount);
      if (coins < cost) {
        alert(`Недостаточно монет! Нужно ${cost}, у вас ${coins}`);
        return;
      }
      const newCoins = coins - cost;
      const newSec = secondsCount + 1;
      const ok = await saveProgressToServer(newCoins, newSec, minutesCount, hoursCount);
      if (ok) {
        setCoins(newCoins);
        setSecondsCount(newSec);
      }
    };

    /**********************************************
     * Покупка (m+1)-й минуты
     **********************************************/
    const buyMinute = async () => {
      const cost = costMinute(minutesCount);
      if (coins < cost) {
        alert(`Недостаточно монет! Нужно ${cost}, у вас ${coins}`);
        return;
      }
      const newCoins = coins - cost;
      const newMin = minutesCount + 1;
      const ok = await saveProgressToServer(newCoins, secondsCount, newMin, hoursCount);
      if (ok) {
        setCoins(newCoins);
        setMinutesCount(newMin);
      }
    };

    /**********************************************
     * Покупка (h+1)-го часа
     **********************************************/
    const buyHour = async () => {
      const cost = costHour(hoursCount);
      if (coins < cost) {
        alert(`Недостаточно монет! Нужно ${cost}, у вас ${coins}`);
        return;
      }
      const newCoins = coins - cost;
      const newHour = hoursCount + 1;
      const ok = await saveProgressToServer(newCoins, secondsCount, minutesCount, newHour);
      if (ok) {
        setCoins(newCoins);
        setHoursCount(newHour);
      }
    };

    return (
      <div className="upgrades-container">
        <h2>🛠️ Улучшения</h2>

        <button className="upgrade-button" onClick={buySecond}>
          ⏱️ Купить секунду
          <br />
          (Цена: {costSecond(secondsCount)})
        </button>

        <button className="upgrade-button" onClick={buyMinute}>
          🕰️ Купить минуту
          <br />
          (Цена: {costMinute(minutesCount)})
        </button>

        <button className="upgrade-button" onClick={buyHour}>
          ⏳ Купить час
          <br />
          (Цена: {costHour(hoursCount)})
        </button>
      </div>
    );
  };

  export default Upgrades;
