import React from 'react';
import '../App.css';

const Stats = () => {
  return (
    <div className="stats-container">
      <h2>📊 Статистика</h2>

      <div className="stats-card">
        <h3>💰 Доход в час</h3>
        <p>1000 монет</p>
      </div>

      <div className="stats-card">
        <h3>🪙 Всего заработано</h3>
        <p>50000 монет</p>
      </div>

      <div className="stats-card">
        <h3>⏱️ Купленные секунды</h3>
        <p>50</p>
      </div>

      <div className="stats-card">
        <h3>🕰️ Купленные минуты</h3>
        <p>20</p>
      </div>

      <div className="stats-card">
        <h3>⏳ Купленные часы</h3>
        <p>5</p>
      </div>
    </div>
  );
};

export default Stats;
